import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { storeTableService } from 'services';
import { SquareLoader } from '../components/loader/square';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { isFoodCourtsPath, isPublicStoresPath } from 'utils/routing';
import { useAuthState } from '../stores';
import { ALLOWED_BYPASS_PATH_ROUTE_NAMES } from 'constants/route.constant';

class ContextState {}

export const AuthContext = createContext(new ContextState());

export const AuthProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, tableToken, setLoading, setTableToken, isNavigating, setIsNavigating } =
    useAuthState();

  const [searchParams] = useSearchParams();

  const emptyValue = useMemo(() => ({}), []);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      setTableToken(token);
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN, token);
    }
  }, [searchParams, setTableToken]);

  useEffect(() => {
    const joinTable = async (): Promise<void> => {
      setLoading(true);
      const _sessionId = await storeTableService.joinTable();
      setLoading(false);
      if (_sessionId !== null && !isNavigating) {
        setIsNavigating(true);
        navigate('/auth');
      }
    };
    if (!isLoading && !isNavigating && tableToken) {
      const token = localStorage.getItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);
      setTableToken(token);
      joinTable();
    }
  }, [tableToken, isLoading, navigate, isNavigating]);

  useEffect(() => {
    const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SESSION_ID);

    if (tableToken && !localStorage.getItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN)) {
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN, tableToken);
    }
    const allowedBypassPaths =
      ALLOWED_BYPASS_PATH_ROUTE_NAMES.includes(location.pathname) ||
      isFoodCourtsPath(location.pathname) ||
      isPublicStoresPath(location.pathname);
    if (!allowedBypassPaths && !sessionId && !tableToken && isNavigating) {
      navigate('/report-error');
    }
  }, [location, tableToken, isNavigating]);

  if (isLoading) {
    return <SquareLoader />;
  }

  return <AuthContext.Provider value={emptyValue}>{children}</AuthContext.Provider>;
};
