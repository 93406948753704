/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { orderService } from 'services/order.service';
import { create } from 'zustand';
import { menuService } from '../services';
import { SelectablePaymentOption } from '../constants';
import {
  IBaseMenuResDto,
  IBaseOrderItemResDto,
  IBillInformation,
  ICouponResDto,
  ICustomerResDto,
  IMenuOrderItemResDto,
  IReceiptInformation,
  ISessionHistoryDto,
  ITableOrderItemResDto,
  Nullable,
  OrderStatus,
  PaymentOption,
} from '../submodules/sicpama-shared';

interface OrderItemProps {
  isLoading: boolean;
  tableOrders: ITableOrderItemResDto[];
  myOrder: ITableOrderItemResDto;
  otherOrders: ITableOrderItemResDto[];
  sessionOrders: ISessionHistoryDto[];
  postDraftOrdersHasSamePaymentOptionWithMe: ITableOrderItemResDto[]; // include my order
  ordersIncludingDraftRegardlessPaymentOption: ITableOrderItemResDto[];
  myInfo?: ICustomerResDto;
  getAllOrderItemsInTheSameTable: (orderStatuses?: OrderStatus[]) => Promise<
    | {
        myOrder: ITableOrderItemResDto;
        isAtLeastOneMenuHasQuantityLessThanMinOrderQuantity: boolean;
      }
    | undefined
  >;
  setPaymentOption: (
    orderId: string,
    paymentOption: PaymentOption,
    isPostPaid?: boolean,
  ) => Promise<void>;
  isAtLeastOnePayAllOrder: boolean; // before pay
  isGuestDiner: boolean;
  isAtLeastOneMenuHasQuantityLessThanMinOrderQuantity: boolean;
  menusHasQuantityLessThanMinOrderQuantity: IBaseMenuResDto[];
  spinTheWheelParticipants: ITableOrderItemResDto[];
  coupons: ICouponResDto[];
  couponIdToCoupon: Record<string, ICouponResDto>;
  stampCoupon?: ICouponResDto;
  billInformation: Record<SelectablePaymentOption, IBillInformation>;
  receiptOrder: IReceiptInformation;
  setReceiptInformation: (orderId: string) => Promise<void>;
  createOrderForPaymentOnly: () => Promise<string>;
}

const getPostDraftOrdersHasSamePaymentOptionWithMe = (
  inputOrders: ITableOrderItemResDto[],
  myOrder: ITableOrderItemResDto,
): ITableOrderItemResDto[] => {
  const seen = new Set();
  // NOTE: A diner can has only one DRAFT/WAITING order but can have multiple PAID orders at the same time
  // In back-end: https://github.com/Sicpama/order-service/blob/develop/src/modules/order/queries/get-consumer-table-order-items.ts#L55
  // latest orders of each diner come first
  // this one helps to filter out the duplicate PAID orders
  const filteredOrders = inputOrders.filter((item) => {
    const duplicate = seen.has(item.customerId);
    seen.add(item.customerId);
    return !duplicate;
  });

  const orders = filteredOrders
    .filter(
      (item) =>
        item.status === OrderStatus.WAITING ||
        item.status === OrderStatus.PAYING ||
        item.status === OrderStatus.PAID ||
        item.status === OrderStatus.PRINTED,
    )
    .filter(
      (o) =>
        o?.paymentOptionEnum?.paymentOptionEnum === myOrder.paymentOptionEnum?.paymentOptionEnum,
    );

  const myOrderIndex = orders.findIndex((o) => o.isMyself);

  // Push my order to the end of the list
  const temp = orders[orders.length - 1];
  orders[orders.length - 1] = orders[myOrderIndex];
  orders[myOrderIndex] = temp;

  return orders;
};

const getOrdersIncludingDraftRegardlessPaymentOption = (
  inputOrders: ITableOrderItemResDto[],
): ITableOrderItemResDto[] => {
  const seen = new Set();
  const filteredOrders = inputOrders.filter((item) => {
    const duplicate = seen.has(item.customerId);
    seen.add(item.customerId);
    return !duplicate;
  });

  const orders = filteredOrders.filter(
    (item) =>
      item.status === OrderStatus.WAITING ||
      item.status === OrderStatus.PAYING ||
      item.status === OrderStatus.PAID ||
      item.status === OrderStatus.PRINTED,
  );

  const myOrderIndex = orders.findIndex((o) => o.isMyself);

  const temp = orders[orders.length - 1];
  orders[orders.length - 1] = orders[myOrderIndex];
  orders[myOrderIndex] = temp;

  return orders;
};

const checkIsAtLeastOneMenuHasQuantityLessThanMinOrderQuantityInSession = (
  sessionOrders: ISessionHistoryDto[],
): { result: boolean; menus: IMenuOrderItemResDto[] } => {
  if (sessionOrders?.length === 0) {
    return {
      result: false,
      menus: [],
    };
  }

  const menus = sessionOrders?.map((order) => order.orderItems.map((item) => item.menu)).flat();

  const menuIdToCurrentNumberOfItems: Record<number, number> = {};

  menus.forEach((menu) => {
    const currentNumberOfItems = menuIdToCurrentNumberOfItems[menu.id];
    if (!currentNumberOfItems) {
      const currentFilteredMenus = menus.filter((item) => item.id === menu.id);
      let total = 0;
      for (const currentMenu of currentFilteredMenus) {
        total += +currentMenu.quantity;
      }
      menuIdToCurrentNumberOfItems[menu.id] = total;
    }
  });

  let result = false;
  const menusHasQuantityLessThanMinOrderQuantity: IMenuOrderItemResDto[] = [];

  menus.forEach((menu) => {
    const { id: menuId, minOrderQuantity } = menu;
    const currentNumberOfItems = menuIdToCurrentNumberOfItems[menuId];
    if (currentNumberOfItems < minOrderQuantity) {
      result = true;
      menusHasQuantityLessThanMinOrderQuantity.push(menu);
    }
  });

  if (result) {
    console.info(
      'menusHasQuantityLessThanMinOrderQuantity: ',
      menusHasQuantityLessThanMinOrderQuantity,
    );
  }

  return {
    result,
    menus: menusHasQuantityLessThanMinOrderQuantity,
  };
};

export const useOrderStore = create<OrderItemProps>((set, get) => ({
  isLoading: false,
  isAtLeastOnePayAllOrder: false,
  isGuestDiner: false,
  tableOrders: [],
  myOrder: {} as ITableOrderItemResDto,
  otherOrders: [],
  sessionOrders: [],
  postDraftOrdersHasSamePaymentOptionWithMe: [],
  ordersIncludingDraftRegardlessPaymentOption: [],
  isAtLeastOneMenuHasQuantityLessThanMinOrderQuantity: false,
  menusHasQuantityLessThanMinOrderQuantity: [],
  spinTheWheelParticipants: [],
  coupons: [],
  couponIdToCoupon: {},
  billInformation: {
    [PaymentOption.ALL]: {
      grandTotal: 0,
      totalDiscount: 0,
    },
    [PaymentOption.MINE]: {
      grandTotal: 0,
      totalDiscount: 0,
    },
    [PaymentOption.ONE_OVER_N]: {
      grandTotal: 0,
      totalDiscount: 0,
    },
    [PaymentOption.SPIN_THE_WHEEL]: {
      grandTotal: 0,
      totalDiscount: 0,
    },
  },
  receiptOrder: {} as IReceiptInformation,
  getAllOrderItemsInTheSameTable: async (orderStatuses?: OrderStatus[]) => {
    set({ isLoading: true });
    const [result, sessionOrders] = await Promise.all([
      orderService.getAllOrderItemsInTheSameTable(orderStatuses),
      orderService.getSessionHistories(orderStatuses),
    ]);
    const orders = result.data;

    const myOrder = orders.find((order) => order.isMyself);
    if (!myOrder) {
      return;
    }
    const coupons = myOrder?.coupons ?? [];
    const normalizedCoupon: Record<string, ICouponResDto> = coupons.reduce(
      (a, b) => ({
        ...a,
        [b.id]: b,
      }),
      {},
    );
    const stampCoupon = coupons.find((x) =>
      x.criteria.customer?.some((y) => y.stamps !== undefined),
    );
    const myInfo = myOrder.customer;
    const isGuestDiner = myInfo?.sicpamaId === null;

    const otherOrders = orders
      .filter((item) => !item.isMyself)
      .filter((item) => item.status === OrderStatus.DRAFT || item.status === OrderStatus.WAITING);
    localStorage.setItem(LOCAL_STORAGE_KEY.CUSTOMER_ID, myInfo.id);
    localStorage.setItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID, myOrder.id);

    const {
      result: isAtLeastOneMenuHasQuantityLessThanMinOrderQuantity,
      menus: menusHasQuantityLessThanMinOrderQuantity,
    } = checkIsAtLeastOneMenuHasQuantityLessThanMinOrderQuantityInSession(sessionOrders);

    const spinTheWheelParticipants = orders.filter(
      (order) =>
        order.paymentOptionEnum?.paymentOptionEnum === PaymentOption.SPIN_THE_WHEEL &&
        (order.status === OrderStatus.DRAFT ||
          order.status === OrderStatus.WAITING ||
          order.status === OrderStatus.PAYING),
    );

    set({
      isLoading: false,
      isGuestDiner,
      tableOrders: orders,
      myInfo,
      myOrder,
      otherOrders,
      sessionOrders,
      ordersIncludingDraftRegardlessPaymentOption:
        getOrdersIncludingDraftRegardlessPaymentOption(orders),
      postDraftOrdersHasSamePaymentOptionWithMe: getPostDraftOrdersHasSamePaymentOptionWithMe(
        orders,
        myOrder,
      ),
      isAtLeastOnePayAllOrder: orders
        .filter(
          (item) =>
            item.status === OrderStatus.DRAFT ||
            item.status === OrderStatus.WAITING ||
            item.status === OrderStatus.PAYING,
        )
        .some((x) => x?.paymentOptionEnum?.paymentOptionEnum === PaymentOption.ALL),
      isAtLeastOneMenuHasQuantityLessThanMinOrderQuantity,
      menusHasQuantityLessThanMinOrderQuantity,
      spinTheWheelParticipants,
      coupons,
      couponIdToCoupon: normalizedCoupon,
      stampCoupon,
      billInformation: result.billInformation,
    });
    return {
      myOrder,
      isAtLeastOneMenuHasQuantityLessThanMinOrderQuantity,
    };
  },
  setPaymentOption: async (orderId, paymentOption, isPostPaid = false) => {
    set({ isLoading: true });
    await orderService.setPaymentOptionToOrder(orderId, paymentOption, isPostPaid);
  },
  setReceiptInformation: async (orderId: string) => {
    const data = await orderService.getReceiptOrder(orderId);
    set({ receiptOrder: data });
  },
  createOrderForPaymentOnly: async () => {
    return orderService.createOrderForPaymentOnly();
  },
}));

interface AddMenuProps {
  menuIdLoading: Nullable<number>;
  isLoading: boolean;
  addMenu: (
    menuId: number,
    options?: {
      orderItemId?: string; // in case we are editing existing order item
      detail?: {
        quantity: number;
        options: Record<string, string | string[] | number | number[]>;
      };
      callback?: VoidFunction;
    },
  ) => Promise<void>;
  setIsMenuLoading: (value: boolean) => void;
}
export const useAddMenu = create<AddMenuProps>((set) => ({
  menuIdLoading: null,
  isLoading: false,
  addMenu: async (menuId, options) => {
    set({ menuIdLoading: menuId, isLoading: true });
    const menuConfig = await menuService.getMenuById(menuId);
    if (menuConfig == null) {
      return;
    }
    const { sessionOrders } = useOrderStore.getState();

    const totalQuantityOfSessionOrders = sessionOrders.reduce((acc, cur) => {
      const orderItems = cur.orderItems.filter((item) => item.menu.id === menuId);
      const subTotal = orderItems.reduce((itemAcc, itemCur) => {
        return itemAcc + itemCur.menu.quantity;
      }, 0);

      return acc + subTotal;
    }, 0);

    // initialize data sent to server
    // usually use when quick adding menu from adding button
    let data = {
      id: Number(menuConfig.id),
      quantity:
        totalQuantityOfSessionOrders < menuConfig.minOrderQuantity
          ? menuConfig.minOrderQuantity - totalQuantityOfSessionOrders
          : 1,
      menuOptions: menuConfig.menuOptions
        .map((menuOption) => {
          if (!menuOption.choices?.length) return null;
          if (menuOption.maxChoices > 1) {
            // return {
            //   id: Number(menuOption.id),
            //   choices: (choiceId as string[]).map((id) => ({ id: Number(id), quantity: 1 })),
            // };
            /** Multiple choice options don't need to have selection now */
            return null;
          }
          return {
            id: Number(menuOption.id),
            choices: [{ id: Number(menuOption.choices[0].id), quantity: 1 }],
          };
        })
        .filter((o) => !(o == null)),
    };

    // in case we have some detail to send, usually when we use in menu detail bottom sheet
    if (options?.detail != null) {
      const updatedDetail = options.detail;
      data = {
        id: Number(menuConfig?.id),
        quantity: Number(updatedDetail.quantity) || Number(menuConfig?.minOrderQuantity) || 1,
        menuOptions: Object.entries(updatedDetail.options ?? {})
          .map(([menuOptionId, choiceId]) => {
            const menuOption = menuConfig?.menuOptions.find(
              (option) => `${option.id}` === `${menuOptionId}`,
            );
            if (menuOption == null) return null;
            if (menuOption.maxChoices > 1) {
              return {
                id: Number(menuOptionId),
                choices: (choiceId as string[]).map((id) => ({ id: Number(id), quantity: 1 })),
              };
            }
            return {
              id: Number(menuOptionId),
              choices: [{ id: Number(choiceId), quantity: 1 }],
            };
          })
          .filter(
            (i): i is { id: number; choices: Array<{ id: number; quantity: number }> } =>
              i != null && i.choices.length > 0,
          ),
      };
    }
    if (options?.orderItemId) {
      await orderService.updateMenuItemToOrder(options.orderItemId, data);
    } else {
      await orderService.addMenuItemToOrder(data);
    }
    options?.callback?.();
    set({ menuIdLoading: null, isLoading: false });
  },
  setIsMenuLoading: (value: boolean) => {
    set({ isLoading: value });
  },
}));

const appliedCoupons = (state: OrderItemProps): ICouponResDto[] => state.coupons || [];
const totalDiscountPriceFromPaymentMethod =
  (paymentOption: PaymentOption) =>
  (state: OrderItemProps): number => {
    switch (paymentOption) {
      case PaymentOption.MINE:
      case PaymentOption.ONE_OVER_N:
        return state.myOrder.couponDiscountTotal;
      case PaymentOption.ALL: {
        let total = 0;
        for (const order of currentOrders(state)) {
          total += +order.couponDiscountTotal;
        }
        return total;
      }
      case PaymentOption.SPIN_THE_WHEEL:
      default:
        return 0;
    }
  };
const draftAndWaitingOrders = (state: OrderItemProps): ITableOrderItemResDto[] =>
  state.tableOrders.filter((item) =>
    [OrderStatus.DRAFT, OrderStatus.WAITING].includes(item.status),
  );
const currentOrders = (state: OrderItemProps): ITableOrderItemResDto[] =>
  state.tableOrders.filter((item) =>
    [OrderStatus.DRAFT, OrderStatus.WAITING, OrderStatus.PAYING].includes(item.status),
  );
const getOrderByPaymentOption =
  (paymentOption: PaymentOption) =>
  (state: OrderItemProps): ITableOrderItemResDto[] =>
    currentOrders(state).filter(
      (order) => order.paymentOptionEnum.paymentOptionEnum === paymentOption,
    );
const payAllOrder =
  (me: boolean) =>
  (state: OrderItemProps): ITableOrderItemResDto | undefined =>
    currentOrders(state)
      .filter((item) => item.isMyself === me)
      .find((x) => x.paymentOptionEnum.paymentOptionEnum === PaymentOption.ALL);
const myPaymentOption = (state: OrderItemProps): PaymentOption =>
  state.myOrder?.paymentOptionEnum?.paymentOptionEnum || PaymentOption.NOT_SELECTED;

const getCurrentOrderItems = (state: OrderItemProps): IBaseOrderItemResDto[] =>
  state.tableOrders
    .filter((order) => order.status === OrderStatus.DRAFT)
    .map((order) => order.orderItems)
    .flat();

const getBillInformation =
  (paymentOption: SelectablePaymentOption) =>
  (state: OrderItemProps): IBillInformation =>
    state.billInformation[paymentOption];

const getNumberOfCustomer = (state: OrderItemProps): number =>
  state.tableOrders.map((o) => o.customer).length;

export const OrderSelectors = {
  appliedCoupons,
  getCurrentOrderItems,
  draftAndWaitingOrders,
  getOrderByPaymentOption,
  payAllOrder,
  myPaymentOption,
  totalDiscountPriceFromPaymentMethod,
  getBillInformation,
  getNumberOfCustomer,
};
