import querystring from 'query-string';
import {
  ICategoryMenuResDto,
  IMenuOrderItemResDto,
  IMenusResDto,
  Nullable,
} from '../submodules/sicpama-shared';
import { getMenuTransByLanguage } from '../utils/getMenuTransByLanguage';
import BaseHttpService from './base-http.service';

const PATH = 'menus';

class MenuService extends BaseHttpService {
  async getMenus(name?: string): Promise<ICategoryMenuResDto[]> {
    const queryString = querystring.stringify({ name });
    const body = await this.get<IMenusResDto>(`${PATH}?${queryString}`);

    return this.transformMenusResponse(body?.data || []);
  }

  async getMenusByStoreId(storeId: number, name?: string): Promise<ICategoryMenuResDto[]> {
    const queryString = querystring.stringify({ name });
    const body = await this.get<IMenusResDto>(`${PATH}/stores/${storeId}?${queryString}`);

    return this.transformMenusResponse(body?.data || []);
  }

  async getMenuById(id: number): Promise<Nullable<IMenuOrderItemResDto>> {
    const menu = await this.get<IMenuOrderItemResDto>(`${PATH}/${id}`);
    return {
      ...menu,
      name:
        getMenuTransByLanguage({
          key: 'name',
          additionalLanguages: menu.additionalLanguages,
          defaultValue: menu.name,
        }) ?? menu.name,
      description:
        getMenuTransByLanguage({
          key: 'description',
          additionalLanguages: menu.additionalLanguages,
          defaultValue: menu.description ?? '',
        }) ?? menu.description,
      menuOptions: menu.menuOptions?.map((option) => ({
        ...option,
        name:
          getMenuTransByLanguage({
            key: 'name',
            additionalLanguages: option.additionalLanguages,
            defaultValue: option.name,
          }) ?? option.name,
        description:
          getMenuTransByLanguage({
            key: 'description',
            additionalLanguages: option.additionalLanguages,
            defaultValue: option.description ?? '',
          }) ?? option.description,
        choices: option.choices?.map((choice) => ({
          ...choice,
          name:
            getMenuTransByLanguage({
              key: 'name',
              additionalLanguages: choice.additionalLanguages,
              defaultValue: choice.name,
            }) ?? choice.name,
          description:
            getMenuTransByLanguage({
              key: 'description',
              additionalLanguages: choice.additionalLanguages,
              defaultValue: choice.description ?? '',
            }) ?? choice.description,
        })),
      })),
    };
  }

  private transformMenusResponse(data: ICategoryMenuResDto[]): ICategoryMenuResDto[] {
    return data.map((categoryMenu) => {
      // Translate the category name/description:
      const translatedCategoryName =
        getMenuTransByLanguage({
          key: 'name',
          additionalLanguages: categoryMenu.additionalLanguages,
          defaultValue: categoryMenu.categoryName,
        }) ?? categoryMenu.categoryName;

      const translatedCategoryDesc =
        getMenuTransByLanguage({
          key: 'description',
          additionalLanguages: categoryMenu.additionalLanguages,
          defaultValue: categoryMenu.description ?? '',
        }) ?? categoryMenu.description;

      const updatedCategoryMenu: ICategoryMenuResDto = {
        ...categoryMenu,
        categoryName: translatedCategoryName,
        description: translatedCategoryDesc,
        menus: categoryMenu.menus.map((menu) => {
          // translate each menu's name/description
          const translatedMenuName =
            getMenuTransByLanguage({
              key: 'name',
              additionalLanguages: menu.additionalLanguages,
              defaultValue: menu.name,
            }) ?? menu.name;

          const translatedMenuDesc =
            getMenuTransByLanguage({
              key: 'description',
              additionalLanguages: menu.additionalLanguages,
              defaultValue: menu.description ?? '',
            }) ?? menu.description;

          return {
            ...menu,
            name: translatedMenuName,
            description: translatedMenuDesc,
          };
        }),
      };

      // Sort the menus if needed
      updatedCategoryMenu.menus.sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));

      return updatedCategoryMenu;
    });
  }
}

export const menuService = new MenuService();
