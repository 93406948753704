import styled from '@emotion/styled';
import { Notifications } from '@sicpama/core-components/lib/notifications';
import { CenterLoader } from 'components/loader';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { AuthProvider, RoutingManagementProvider } from 'contexts';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root')!);

const StyledNotifications = styled(Notifications)`
  .mantine-Notification-root {
    padding: 12px 16px;
    background-color: white;
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #2a297a;
    .mantine-Text-root,
    .mantine-CloseButton-root {
      color: #5b5b5b;
      font-size: 14px;
    }
  }
`;

// handling persist token at the application's entry point, before React even renders:
const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get('token');
if (token) {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN, token);
  } catch (error) {}
}

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY!}
      options={{
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST ?? 'https://app.posthog.com',
      }}
    >
      <React.Suspense fallback={<CenterLoader />}>
        <StyledNotifications position="top-center" zIndex={10000000} />
        <BrowserRouter>
          <AuthProvider>
            <RoutingManagementProvider>
              <App />
            </RoutingManagementProvider>
          </AuthProvider>
        </BrowserRouter>
      </React.Suspense>
    </PostHogProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.  Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
