import { ICouponAdvertResDto } from '../submodules/sicpama-shared';
import { StoreResDto } from '../types';
import BaseHttpService from './base-http.service';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';
import { randomId } from '../utils/string';

const PATH = 'consumer-stores';

class StoreService extends BaseHttpService {
  async getStoreData(): Promise<StoreResDto> {
    const body = await this.get<StoreResDto>(`${PATH}`);
    return body;
  }

  async getStoreById(storeId: number): Promise<StoreResDto> {
    const body = await this.get<StoreResDto>(`${PATH}/${storeId}`);
    return body;
  }

  async createVirtualTable(storeId: number): Promise<{ storeId: number; token: string }> {
    const requestId = randomId(16);
    localStorage.setItem(LOCAL_STORAGE_KEY.REQUEST_ID, requestId);
    const response = await this.post<{ storeId: number; token: string }>(
      `${PATH}/${storeId}/virtual-tables`,
      {},
    );
    localStorage.removeItem(LOCAL_STORAGE_KEY.CUSTOMER_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);
    return response;
  }

  async getStoreCouponAdvert(storeId: string, couponId: string): Promise<ICouponAdvertResDto> {
    const response = await this.get<ICouponAdvertResDto>(
      `${PATH}/${storeId}/coupon-advert/${couponId}`,
    );
    return response;
  }

  async couponPickup(
    storeId: string,
    couponId: string,
    customerId: string,
  ): Promise<{ message: string }> {
    const response = await this.post<{ message: string }>(
      `${PATH}/${storeId}/coupons/${couponId}/customers/${customerId}`,
    );
    return response;
  }
}

export const storeService = new StoreService();
