export enum WEB_APP_HTTP_HEADERS {
  STORE_TABLE_TOKEN = 'store-table-token',
  GUEST_ACCESS_TOKEN = 'guest-access-token',
  CUSTOMER_ID = 'customer-id',
  ORDER_ID = 'order-id',
  REQUEST_ID = 'request-id',
  SESSION_ID = 'session-id',
}

export enum KDS_HTTP_HEADERS {
  STORE_FINGERPRINT = 'store-fingerprint',
}
