export const LOCAL_STORAGE_KEY = {
  // renaming customerId to cid to prevent possible reuse of wrong customerId following fingerprint clash
  CUSTOMER_ID: 'cid',
  SESSION_ID: 'sid',
  // request id for virtual tables
  REQUEST_ID: 'requestId',
  CURRENT_TOKEN: 'currentToken',
  MY_CURRENT_ORDER_ID: 'myCurrentOrderId',
  MOVE_TO_PG_SIDE: 'moveToPGSide',
  GUEST_ACCESS_TOKEN: 'guestAccessToken',
  VIEW_IN_GRID: 'viewInGrid',
  SELECTED_CATEGORY_ID: 'selectedCategoryId',
  MUST_FINISH_SPLIT_EVENLY: 'mustFinishSplitEvenly',
  BEFORE_SIGN_IN_PATH: 'beforeSignInPath',
  ALREADY_CLAIMED_COUPON: 'alreadyClaimedCoupon',
  TOSS_PAYMENT_CONFIRMED: 'tossPaymentConfirmed',
};
