import { UpdateCustomerInformationReqDto } from '../types';
import BaseHttpService from './base-http.service';
import { ICustomerResDto } from 'submodules/sicpama-shared';

const PATH = 'customers';

class CustomerService extends BaseHttpService {
  async postLogin(): Promise<void> {
    await this.post(`${PATH}/post-login`, {});
  }

  async updateInformation(params: UpdateCustomerInformationReqDto): Promise<void> {
    await this.put(`${PATH}/information`, params);
  }

  async getCustomerInformation(): Promise<ICustomerResDto> {
    return this.get(`${PATH}/me`);
  }
}

export const customerService = new CustomerService();
